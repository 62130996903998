import React from 'react';
import { Nav } from 'react-bootstrap';

export default function Address() {
  return (
    <Nav.Link>
      Hudson, MA
    </Nav.Link>
  );
}
